import React from "react";
import RectInfo from "./RectInfo";

// 默认框选
class DefaultRects extends React.Component {
  rectClick = (e, item) => {
    Utils.stopPropagation(e);
    const { onClickDefaultRect, getRectImage } = this.props;
    const rectUrl = getRectImage(item.rect);
    onClickDefaultRect && onClickDefaultRect(item, rectUrl);
  };

  // 计算结构化框层级
  computedZIndex = rects => {
    let areas = rects.map(v => {
      let width = v.rect[2];
      let height = v.rect[3];
      v.area = width * height;
      return v;
    });
    return areas.sort((a, b) => b.area - a.area);
  };

  // 使用鼠标事件避免拖动时触发点击
  onMouseDown = () => {
    this.timer = new Date()*1;
  }
  onMouseUp = (e, item) => {
    const timeEnd = new Date()*1;
    if(timeEnd - this.timer < 200) {
      this.rectClick(e, item);
      this.timer = null
    } 
  }

  render() {
    let { rects = [], containerDom, showRectInfo = false, showDefaultRect = true } = this.props;
    let temp = rects;
    if (showDefaultRect) {
      temp = rects.filter(v => v.default);
    }
    temp = this.computedZIndex(temp);
    return temp.map((v, k) => {
      return (
        <div className={`rect-item-wrapper rect-item-${v.type}-wrapper`}>
          <div
            className={"rect-item"}
            onClick={(e) => this.rectClick(e, v)}
            // onMouseDown={this.onMouseDown}
            // onMouseUp={(e) => this.onMouseUp(e, v)}
            style={{
              left: v.rect[0],
              top: v.rect[1],
              width: v.rect[2],
              height: v.rect[3],
              zIndex: k
            }}
          >
            <div className="border-top-decorator" />
            <div className="border-bottom-decorator" />
          </div>
          {showRectInfo && v.tags && !!v.tags.length && <RectInfo item={v} containerDom={containerDom} />}
        </div>
      );
    });
  }
}

export default DefaultRects;
